import React, {useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";
import './Menu.css';

function Menu() {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('fondo');
  }, []); 
  
  return (
    <div className='menu'>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to= "fondo">Secretaria de Hacienda:: Albania</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="predial">Predios</Nav.Link>
            <Nav.Link as={Link} to="industria">Negocios</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link as={Link} to="saliendo">Salir</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default Menu;