import React from 'react'
import  Menu from '../componentes/Menu'
import  Footer from '../componentes/Footer'
import { Outlet } from 'react-router-dom'

const Principalciudadanos = () => {
  return (
    <div> 
        <Menu/>
          <Outlet></Outlet>
       <Footer/>
     </div>
  )
}

export default Principalciudadanos

