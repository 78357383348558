import React from 'react'
import './fondo.css';

const Fondo = () => {
  return (
    <div>
    <div class="bg-image">
        <header>
     <h1>Bienvenido al Municipio de Albania - La Guajira</h1>
   </header>
   
   <section>
   <div className="container">
   <div className="row">
     <div className="col-md-6 col-lg-4 mb-4">
         <div className="card h-100">
               <img className="card-img-top" src="https://via.placeholder.com/300x200" alt="Card image cap" />
               <div className="card-body">
                 <h5 className="card-title">Tarjeta 1</h5>
                 <p className="card-text">Texto de ejemplo para la tarjeta 1.</p>
               </div>
         </div>
     </div>
     <div className="col-md-6 col-lg-4 mb-4">
         <div className="card h-100">
               <img className="card-img-top" src="https://via.placeholder.com/300x200" alt="Card image cap" />
               <div className="card-body">
                 <h5 className="card-title">Tarjeta 1</h5>
                 <p className="card-text">Texto de ejemplo para la tarjeta 1.</p>
               </div>
         </div>
     </div>
     <div className="col-md-6 col-lg-4 mb-4">
         <div className="card h-100">
               <img className="card-img-top" src="https://via.placeholder.com/300x200" alt="Card image cap" />
               <div className="card-body">
                 <h5 className="card-title">Tarjeta 1</h5>
                 <p className="card-text">Texto de ejemplo para la tarjeta 1.</p>
               </div>
         </div>
     </div>
   </div>
 </div>
   </section></div>
   </div>
  )
}

export default Fondo