import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter,Routes,Route} from "react-router-dom"
import  Principal from './componentes/Principal'
import  Principalciudadanos from './Paginas/Principalciudadanos'
import  Predial from './Paginas/Predial'
import  Industria from './Paginas/Industria'
import  Fondo from './Paginas/Fondo'
import  Saliendo from './Paginas/Saliendo'
import  Cambiarclave from './Paginas/Cambiarclave'

window.ciudadanologueado = 0


function App() {
  return (
      <div className="App">
        <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Principal/>} />
              <Route path="/principalciudadanos/*" element={<Principalciudadanos/>} > 
             
                  <Route path="predial" element={<Predial/>} /> 
                  <Route path="industria" element={<Industria/>} /> 
                  <Route path="cambiarclave" element={<Cambiarclave/>} /> 
                   <Route path="fondo" element={<Fondo/>} /> 
                   <Route path="saliendo" element={<Saliendo/>} /> 
               
              </Route>
          </Routes>
        </BrowserRouter>
        </div>
   );
}

export default App;
