import React from 'react'
import Principalciudadanos from '../Paginas/Principalciudadanos'
import Login from './Login';

const Principal = () => {
  const loginx =  window.ciudadanologueado 
  return (
     <div >
            {loginx
              ? <Principalciudadanos/>
              : <Login/>
            }
        </div>       
      )
}





export default Principal