import React from 'react'
import { useState } from 'react';
import { Card} from 'react-bootstrap';
import './login.css';
import axios from 'axios';
import Swal from 'sweetalert2'
import imagenentidad from '../Imagenes/escudoalbania2.png';
import {useNavigate} from "react-router-dom";
const CryptoJS = require("crypto-js");
const buttonStyle = {
  background: '#38426f',
  color : 'white',
};


const Login = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState("");

 
    const [clave,Setclave] = useState('');
    const [codigo,Setcodigo] = useState('');

  function changeClave(event) {
      Setclave(event.target.value);
  }

function handleSumiChangeCodigo(event) {
    Setcodigo(event.target.value);  
 }

    const peticionGet=async()=>{
    const baseUrl2="https://soul-group.net/Api_soul/logintercero.php?codigo="+String(codigo);
    await axios.get(baseUrl2)
    .then( response=>{ 
           window.documentotercero = codigo
           setPassword(response.data.password)
           const passwordMD5 = CryptoJS.SHA1(clave).toString();
           if(passwordMD5 === password){
                window.ciudadanologueado = 1
                navigate('/principalciudadanos')
              
              } else{
                   Swal.fire({
                   title: 'Error!',
                   text: 'El Documento o la Clave estan erradas',
                   icon: 'error',
                   confirmButtonText: 'OK'
                  })
            }
          })
      .catch(error=>{
        console.log('Salio Error');
        console.log(error);
      })
    }
     
    function ingresar(){
        peticionGet();
       
        }
  return (
    <div className="containerlogin">
    <div className="screen__content">
      <Card className="login" alt='Logo'>
        <img className="card-img-top" src={imagenentidad} alt='Logo'/>
        <p>Bienvenido a Nuestra Oficina Virtual</p>    
        <div className="login__field">
          <i className="login__icon fas fa-user" />
          <input type="text" className="login__input" placeholder="Doc. de Identidad" value={codigo} onChange={handleSumiChangeCodigo}/>
        </div>
        <div className="login__field">
          <i className="login__icon fas fa-lock" />
          <input type="password" className="login__input" placeholder="Password o Documento" value={clave} onChange={changeClave}/>
        </div>
        <button className = "btn" style={buttonStyle}  onClick={ingresar}>
            Ingresar
          </button>	
      </Card>
    </div>
</div>
  )
}

export default Login