import React, { Component } from 'react';

import $ from 'jquery';

import 'datatables.net-dt/css/jquery.dataTables.css';

import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';

import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

import 'datatables.net-buttons/js/dataTables.buttons.js';

import 'datatables.net-buttons/js/buttons.html5.min.js';

import 'datatables.net-buttons/js/buttons.print.min.js';

import 'datatables.net-buttons/js/buttons.colVis.min.js';

import 'datatables.net-responsive/js/dataTables.responsive.js';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'datatables.net-dt/css/jquery.dataTables.css';

import 'datatables.net-responsive-dt/css/responsive.dataTables.css';

import 'datatables.net-responsive-dt';

import Modal from 'react-bootstrap/Modal';

//se importan todos los botones posibles debido a errores cometidos

const codigop = '40877411A'
 

class Predial extends Component {

  constructor(props) {

    super(props);

    this.state = {

      data: [],

      modalShow: false

    };

    this.showModal = this.showModal.bind(this);

    this.agregarUsuario = this.agregarUsuario.bind(this); // agregar usus

    this.mainRef = React.createRef();

    this.table = null;

  }

  

  //constructor para el Modal

  showModal() {

    this.setState({ modalShow: true });

  }

  //evento de agregar usando constantes

  agregarUsuario(event) {

    event.preventDefault();

    const nombre = document.getElementById('nombre').value;

    const apellido = document.getElementById('apellido').value;

    const email = document.getElementById('email').value;

    const edad = document.getElementById('edad').value;

    const telefono = document.getElementById('telefono').value;

    const pais = document.getElementById('pais').value;

 

    const newUser = {

      name: { first: nombre, last: apellido },

      email: email,

      dob: { age: edad },

      phone: telefono,

      location: { country: pais }

    };

    console.log('Agregando usuario...');

    const rowNode = this.table.row.add(newUser).draw().node();

    $(rowNode).addClass('nuevo-usuario');

    this.setState((prevState) => ({

      data: prevState.data.concat(newUser),

      modalShow: false

    }), () => {

      this.table.draw();

    });

  }

  toggleResponsive() {

    const table = this.el.DataTable();

    const responsive = table.responsive;

    table.responsive(!responsive);

  }

  //se usa el fetch para consumir el api

  componentDidMount() {
    const url2 = 'https://soul-group.net/Api_soul/predioconsulta.php?codigo='+String(codigop)
    console.log(url2)
    fetch('https://soul-group.net/Api_soul/predioconsulta.php?codigo='+String(codigop))

      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        this.setState({ data: data.results });

        if (!this.table) {

          this.table = $(this.mainRef.current).DataTable({

            "ajax": {

              "url": "https://soul-group.net/Api_soul/predioconsulta.php?codigo="+String(codigop),

              "dataSrc": "results",

            },

            "columns": [

              { title: 'Num.Igac', data: 'codigo' },
              { title: 'Direccion', data: 'direccion' },
              { title: 'Matricula', data: 'matricula' },
              { title: 'Avaluo', data: 'avaluo' },
              { title: 'Destinacion', data: 'destinacion' },
            { title: 'Zona', data: 'nombre' },
            ],

            "dom": 'T<"clear">lfrtip',

            "tableTools": {

              "aButtons": [

                "copy",

                "print",

                {

                  "sExtends": "collection",

                  "sButtonText": "Save",

                  "aButtons": ["csv", "xls", "pdf"]

                }

              ]

            },

            "initComplete": function(settings, json) {

              document.cookie = 'SameSite=None; Secure';

            },

            "responsive": true // Habilita el plugin responsive

          });

        } else {

          this.table.ajax.reload();

        }

      })

      .catch((error) => {

        console.error('Error fetching data:', error);

      });

  }

  render() {

 

    const columns = [

      { title: 'Num.Igac', data: 'codigo' },
              { title: 'Direccion', data: 'direccion' },
              { title: 'Matricula', data: 'matricula' },
              { title: 'Avaluo', data: 'avaluo' },
              { title: 'Destinacion', data: 'destinacion' },
            { title: 'Zona', data: 'nombre' },

    ];

// se usa el Modal launch demo con form de bootstrap-react

    return (

      <><div>

        <table ref={el => this.el = el}></table>

      </div>

      <button className="btn btn-success" onClick={() => this.showModal()}>

        Agregar +

      </button>

      <Modal show={this.state.modalShow} onHide={() => this.setState({ modalShow: false })}>

        <Modal.Header closeButton>

          <Modal.Title>Agregar usuario</Modal.Title>

        </Modal.Header>

        <Modal.Body>

          <form onSubmit={this.agregarUsuario}>

            <div className="form-group">

              <label htmlFor="nombre">Nombre:</label>

              <input type="text" className="form-control" id="nombre" />

            </div>

            <div className="form-group">

              <label htmlFor="apellido">Apellido:</label>

              <input type="text" className="form-control" id="apellido" />

            </div>

            <div className="form-group">

              <label htmlFor="email">Email:</label>

              <input type="email" className="form-control" id="email" />

            </div>

            <div className="form-group">

              <label htmlFor="edad">Edad:</label>

              <input type="number" className="form-control" id="edad" />

            </div>

            <div className="form-group">

              <label htmlFor="telefono">Teléfono:</label>

              <input type="tel" className="form-control" id="telefono" />

            </div>

            <div className="form-group">

              <label htmlFor="pais">País:</label>

              <input type="text" className="form-control" id="pais" />

            </div>

            <button type="submit" className="btn btn-outline-success">

              Agregar

            </button>

          </form>

        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>

      </Modal>

      <div className='table-responsive'>

          <table ref={this.mainRef} className='table table-striped table-bordered'>

            <thead>

              <tr className="table-dark">

                {columns.map((column) => (

                  <th key={column.data}>{column.title}</th>

                ))}

               

              </tr>

            </thead>

            <tbody>

           


              {this.state.data.length > 0 && this.state.data.map((item) => (

                <tr key={item.codigo} className="table-light">

                  <td>{item.codigo}</td>

                  <td>{item.direccion}</td>

                  <td>{item.matricula}</td>

                  <td>{item.avaluo}</td>

                  <td>{item.destinacion}</td>

                  <td>{item.nombre}</td>

                </tr>

              ))}

            </tbody>

          </table>

        </div></>

    );

  }

}

//se usa el map para la tabla

export default Predial;